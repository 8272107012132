@font-face {
  font-family: 'ITCFranklin';
  src: url('../fonts/ITCFranklinGothicStd-MdCd.otf');
  font-weight: normal;
}
@font-face {
  font-family: 'ITCFranklin';
  src: url('../fonts/ITCFranklinGothicStd-DmCd.otf');
  font-weight: bold;
}

.lds-ripple {
  display: inline-block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin-top: 20vh;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

@media (min-width: 992px) {
  .typo-line {
    padding-left: 140px;
    margin-bottom: 40px;
    position: relative;
  }

  .typo-line .category {
    transform: translateY(-50%);
    top: 50%;
    left: 0px;
    position: absolute;
  }
}

.all-icons [class^="pe-"] {
  font-size: 40px;
}
.all-icons input {
  border: 0;
}
.all-icons .font-icon-detail {
  text-align: center;
  padding: 45px 0px 30px;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  margin: 15px 0;
}
.all-icons .font-icon-container a {
  padding: 5px 0;
  display: block;
  color: #666666;
}
.all-icons .font-icon-detail input {
  margin: 25px auto 0;
  width: 100%;
  text-align: center;
  display: block;
  color: #aaa;
  font-size: 13px;
}
.all-icons h5 {
  margin-top: 60px;
}
.all-icons > div > .row:first-child h5 {
  margin-top: 15px;
}

.places-buttons .btn {
  margin-bottom: 30px;
}

.buttons-with-margin .btn {
  margin: 5px;
}

.buttons-with-margin .btn-group .btn {
  margin: 5px 0px 0px 0px;
}

.animated .img-rounded {
  width: 150px;
  height: 150px;
  overflow: hidden;
  margin: 0 auto;
  display: block;
}
.animated .img-rounded img {
  width: 100%;
}
.heart {
  color: #eb5e28;
  animation: heathing 1s ease infinite;
}

@keyframes heathing {
  0% {
    transform: scale(0.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(0.75);
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 30px;
}
.lds-ellipsis div {
  position: absolute;
  top: 18px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #aaa;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
